import React, { FC } from 'react'
import { CardRaised } from 'components/card/custom/CardRaised'
import { TitleCentered } from 'components/common/common'
import { NavTabs } from 'components/tabs/NavTabs'
import icons from 'components/theme/icons'
import { IconDefinition } from 'components/theme/icons';
import useI18n from 'use/i18n/useI18n'
import { routes } from 'setup/constants'
import { SloganStyled } from 'components/typography/view/index/styled'

interface ITabs {
  button: string;
  icon: IconDefinition;
  href?: string;
}

interface ICardCategoriesProps {
  tabs: ITabs[];
  labels: any;
}

const labelIds = {
  title2: 'pages.home.title.2',
  slogan3: 'pages.home.slogan.3',
  comic: 'pages.home.categories.comic',
  cinema: 'pages.home.categories.cinema',
  country: 'pages.home.categories.country',
  all: 'pages.home.categories.all',
  soccer: 'pages.home.categories.soccer',
  soccerClassic: 'pages.home.categories.soccerClassic'
}

export const CardCategoriesCompose: FC = ({
}) => {
  const labels = useI18n().tm(labelIds)

  return (
    <CardCategories
      labels={{
        card: {
          title: labels.title2,
          text: labels.slogan3
        }
      }}
      tabs={[
        { button: labels.soccer, icon: icons.soccer, href: routes.public('futbol')},
        { button: labels.cinema, icon: icons.cinema, href: routes.public('cine_tv') },
        { button: labels.soccerClassic, icon: icons.soccerClassic, href: routes.public('futbol_clasico') },
        { button: labels.comic, icon: icons.comic, href: routes.public('pokemon_tcg_series') },
        { button: labels.country, icon: icons.country, href: routes.public('futbol_ligas_del_mundo') },
        // { button: labels.all, icon: icons.unfold, href: '#' }
      ]}
    />
  )
}

export const CardCategories: FC <ICardCategoriesProps> = ({
  tabs,
  labels
}) => {
  return (
    <CardRaised color='info' icon={icons.categories}
      content={
        <>
          <TitleCentered>{labels.card.title}</TitleCentered>
          <SloganStyled p={[ labels.card.text ]} />
        </>
      }>
      <NavTabs
        color="info"
        tabs={tabs}/>
    </CardRaised>
  )
}