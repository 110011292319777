import React, { useEffect } from 'react'

import { nav } from 'setup/constants'

import useI18n from 'use/i18n/useI18n'

import { HeroChanges } from 'components/typography/view/index/HeroChanges'
import { CardCategoriesCompose } from 'compose/card/index/CardCategoriesCompose'
import { ILayout, Layout } from 'components/layout/Layout'
import { CardCollectionsCompose } from 'compose/card/index/CardCollectionsCompose'
import { IndexHeroCompose } from 'compose/hero/IndexHeroCompose'
import { PublicHeaderCompose } from 'compose/header/PublicHeaderCompose'
import { PopupAuthCompose } from 'compose/popup/PopupAuthCompose'
import { usePageQuery } from 'use/fetch/useQuery'
import { ChangeState } from 'model/UserChange.model'
import { INDEX_CHANGES_QUERY } from 'graphql/query'
import { IUserExchangeModel } from 'model/UserExchange.model'
import { mapperHomeChanges } from 'model/mapper'

const labelIds = {
  title: 'pages.home.title.3',
  slogan4: 'pages.home.slogan.4',
}

// function IndexPage({ data }) {
  // const topCollections = (data.api.top || {}).data || []
  // const lastCollections = ((data.api.last || {}).data || []).map(ob => ({ collection: ob }))
  // const changes = ((data.api.changes || {}).data || [])
function IndexPage({ location, defaultTab }:{
  location: string,
  defaultTab?: number,
}) {
  const changesQuery = usePageQuery<IUserExchangeModel>('userExchangesPage', INDEX_CHANGES_QUERY, { page: 0, per_page: 10, direction: "DESC", noMerge: true })

  useEffect(() => {
    changesQuery.get({ state: ChangeState.PROGRESS })
  }, [])
  const labels = useI18n().tm(labelIds)

  return (
    <>
      <PublicHeaderCompose>
        <IndexHeroCompose page='indexPage' />
      </PublicHeaderCompose>
      <Layout variant={ILayout.PLAIN}>
        <CardCollectionsCompose />
        <CardCategoriesCompose />
        <HeroChanges list={changesQuery.list?.filter(c => !!c.user).map(mapperHomeChanges)} link={nav.publicChanges}
          labels={{
            card: {
              title: labels.title,
              text: labels.slogan4
            }
          }}/>
      </Layout>
      <PopupAuthCompose defaultTab={defaultTab} />
    </>
  )
}
export default IndexPage


/*
export const query = graphql`
  query IndexPageQuery {
    site {
      siteMetadata {
        description
      }
    }
    api {
      top: searchCollectionsPage(
        page: { page: 0, per_page: 10, direction: "ASC" },
        filter: {}
      ) {
        total
        data {
          collection {
            code
            name
          }
          collectors {
            total
            recent
          }
        }
      }
      last: collectionsPage(
        page: { page: 0, per_page: 10, direction: "DESC", sort: "LAST" },
        filter: {}
      ) {
        total
        data {
          code
          name
        }
      }
      changes: userExchangesPage(
        page: { page: 0, per_page: 10, direction: "DESC",},
        filter: { state: PROGRESS}
      ) {
        total
          data {
            change {
              id
              stateId
              startDate
              endDate
              lastUpdater
              him
            }
            user {
              username
              avatar
            }
            exchanges {
              collection {
                  code
                  name
              }
              mine
              his
            }
          }
      }
    }
  }
`
*/