import React, { FC } from 'react'

import icons from 'components/theme/icons'
import { CardRaised } from 'components/card/custom/CardRaised'
import { TitleCentered } from 'components/common/common'
import { ListHorizontal } from 'components/common/common.styled'
import { IUserExchangeModel } from 'model/UserExchange.model'
import { CardChangeHomeCompose } from 'compose/card/change/CardChangeHomeCompose'
import { CardCollectionSkeleton } from 'components/card/styled'
import { SloganStyled } from 'components/typography/view/index/styled'

interface IHeroChangesProps {
  labels: any;
  list: IUserExchangeModel[];
  link: (item: IUserExchangeModel) => string
}
export const HeroChanges: FC<IHeroChangesProps> = ({
  labels,
  list,
  link
}) => {

  return (
    <CardRaised variant='invert' color='gray' overflow icon={icons.userChanges}
      content={<>
        <TitleCentered>{labels.card.title}</TitleCentered>
        <SloganStyled p={[ labels.card.text ]} />
        </>}>
      <ListHorizontal
        list={list}
        linkTo={link}
        item={CardChangeHomeCompose}
        skeleton={<CardCollectionSkeleton />}>
      </ListHorizontal>
    </CardRaised>
  )
}

