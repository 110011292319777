
import React, { FC, memo } from 'react'
import { IconDefinition } from 'components/theme/icons';
import { ISearchCollectionModel } from 'model/SearchCollection.model'
import CollectionModel from 'model/Collection.model'

import useI18n from 'use/i18n/useI18n'

import { AdditionalDataStyled, AdditionalParagraphStyled } from 'components/card/collection/styled'
import { Icon } from 'components/styled'
import { CardCollection } from 'components/card/collection/CardCollection'


interface IAdditionalItem {
  icon?: IconDefinition;
  color: 'success' | 'danger' | 'gray' | 'info';
  text: string;
}

const AdditionalItem: FC<IAdditionalItem> = ({ color, text, icon }) => (
  <AdditionalParagraphStyled color={color}>{icon && <Icon icon={icon} /> } {text}</AdditionalParagraphStyled>
)

const labelIds = [
  'pages.dashboard/colecciones/nueva.collectors',
  'pages.dashboard/colecciones/nueva.recent'
]

const CardCollectionDashboardNewCollectionComposeMemo: FC<ISearchCollectionModel> = ({
  collection,
  collectors
}) => {

  if (!collection) {
    return <></>
  }
  const { t } = useI18n()
  const { code, name } = collection
  const { total, recent } = collectors || {}
  const image = CollectionModel.getImage(code)
  const numCollectors = total ? t(labelIds[0], { num: total }) : ''
  const numRecent = recent ? t(labelIds[1], { num: recent }) : ''

  return (
    <CardCollection
      image={image}
      name={name}
      select={() => {}}>
      <AdditionalDataStyled>
        <AdditionalItem color={'info'} text={numCollectors} />
        <AdditionalItem color={'success'} text={numRecent} />
      </AdditionalDataStyled>
    </CardCollection>
  )
}





export const CardCollectionDashboardNewCollectionCompose = memo(CardCollectionDashboardNewCollectionComposeMemo)