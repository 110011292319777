import React, { FC, memo } from 'react'
import { IExchangeData } from 'model/UserExchange.model'
import { ExchangeFull } from 'components/card/change/ExchangeFull'
import CollectionModel from 'model/Collection.model'

interface IProps {
  exchange: IExchangeData;
}
const ExchangeFullComposeMemo: FC<IProps> = ({ exchange }) => {
  const { collection, mine, his } = exchange
  const { name, code } = collection || {}
  const image = CollectionModel.getImage(code)
  return (
    <ExchangeFull
      image={image}
      name={name}
      mine={mine.length} his={his.length} 
     />
  )
}

export const ExchangeFullCompose = memo(ExchangeFullComposeMemo)