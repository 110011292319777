import React, { FC, useEffect } from 'react'
import { CardRaised } from 'components/card/custom/CardRaised'
import { Title, TitleCentered } from 'components/common/common'
import icons from 'components/theme/icons'
import useI18n from 'use/i18n/useI18n'
import { nav } from 'setup/constants'
import { ListHorizontal } from 'components/common/common.styled'
import { CardCollectionDashboardNewCollectionCompose } from '../collection/CardCollectionDashboardNewCollectionCompose'
import { CardCollectionSkeleton } from 'components/card/styled'
import { ISearchCollectionModel } from 'model/SearchCollection.model'
import { INDEX_TOP_COLLECTIONS_QUERY, INDEX_LAST_COLLECTIONS_QUERY } from 'graphql/query'
import { ICollectionModel } from 'model/Collection.model'
import { mapperHomeLastCollections, mapperHomeTopCollections } from 'model/mapper'
import { usePageQuery } from 'use/fetch/useQuery'
import { ListButtonStyled } from 'components/filter/styled'
import { SloganStyled } from 'components/typography/view/index/styled'

const labelIds = {
  title1: 'pages.home.title.1',
  slogan2: 'pages.home.slogan.2',
  titleTop: 'pages.home.title.top',
  titleLast: 'pages.home.title.last',
  loadMore: 'filter.loadmore',
}
export const CardCollectionsCompose: FC = ({
}) => {
  const {
    title1,
    slogan2,
    titleTop,
    titleLast,
    loadMore,
  } = useI18n().tm(labelIds)

  const topCollectionsQuery = usePageQuery<ISearchCollectionModel>('searchCollectionsPage', INDEX_TOP_COLLECTIONS_QUERY, { per_page: 10, direction: "ASC" })
  const lastCollectionsQuery = usePageQuery<ICollectionModel>('collectionsPage', INDEX_LAST_COLLECTIONS_QUERY, { per_page: 10, direction: "DESC", sort: "LAST" })  

  useEffect(() => {
    topCollectionsQuery.get({})
    lastCollectionsQuery.get({})
  }, [])

  const topCollections = topCollectionsQuery.list?.map(mapperHomeTopCollections)
  const lastCollections = lastCollectionsQuery.list?.map(mapperHomeLastCollections)

  return (
    <CardRaised variant='invert' color='success' overflow icon={icons.allCollections}
      content={
        <>
          <TitleCentered>{title1}</TitleCentered>
          <SloganStyled dark p={[ slogan2 ]} />
        </>
      }>
      <Title>{titleTop}</Title>
      <List loadMore={loadMore}
        list={topCollections} fetchMore={topCollectionsQuery.fetchMore}>
      </List>
      <Title>{titleLast}</Title>
      <List loadMore={loadMore}
        list={lastCollections} fetchMore={lastCollectionsQuery.fetchMore}>
      </List>
    </CardRaised>
  )
}


interface IListProps {
  list: ISearchCollectionModel[];
  fetchMore: () => void;
  loadMore: string;
}
const List: FC<IListProps> = ({
  list,
  fetchMore,
  loadMore
}) => (
  <ListHorizontal
    list={list}
    linkTo={nav.publicSearchCollection}
    item={CardCollectionDashboardNewCollectionCompose}
    skeleton={<CardCollectionSkeleton />}>
    <ListButtonStyled onClick={fetchMore}>{loadMore}</ListButtonStyled>
  </ListHorizontal>
)

/*
 <ListHorizontal
          list={activeChangesQuery.list?.map(mapperDashboardActiveChanges)}
          onClick={item => onClickChange(item)}
          item={CardChangeDashboardHomeCompose}>
          <SearchNewChangesButton linkTo={routes.userChangeSearch}>
            {findNew}
          </SearchNewChangesButton>
          { activeChangesQuery.fetchMore && <LoadMoreButton onClick={activeChangesQuery.fetchMore}>{loadMore}</LoadMoreButton>}
        </ListHorizontal> 
        */