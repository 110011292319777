import styled, { css } from 'styled-components';
import { Icon } from 'components/styled';
import { grayColor } from 'components/theme/constants';
import { buttonBackground, whiteBackground } from 'components/common/styled';

export const NavTabWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-around;
`
export const NavTab = styled.div`
  float: left;
  position: relative;
  text-align: center;
  transition: all .3s;
  padding: 0;
  color: ${grayColor[15]};
  height: auto;
  opacity: 1;
  margin: 0.5rem;
  min-height: unset;

  font-size: 0.8rem;
  font-weight: 500;

  border-radius: 1rem;
  border: 1px solid rgba(44,44,44,0.5);

  width: 6rem;
  height: 6rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  ${props => props.invert ? whiteBackground(props) : buttonBackground(props)}
`
export const NavTabIcon = styled(Icon).attrs(props => ({
  size: '2x'
}))`

  display: block;
  margin: 0 auto;
`

