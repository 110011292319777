import React, { FC } from 'react'
import { Exchange } from './Exchange'
import { AvatarCollectionStyled, ExchangeStyled, ExchangeWrapperStyled, NameCollectionStyled } from './styled'

interface IExchangeFullProps {
  image: string;
  name: string;
  mine: number;
  his: number;
}
export const ExchangeFull: FC<IExchangeFullProps> = ({ image, name, mine, his }) => (
  <ExchangeStyled>
    <AvatarCollectionStyled src={image}/>
    <ExchangeWrapperStyled>
      <NameCollectionStyled>{name}</NameCollectionStyled>
      <Exchange mine={mine} his={his} />
    </ExchangeWrapperStyled>
  </ExchangeStyled>
)