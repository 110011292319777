import React, { FC, memo } from 'react'

import { CardChange } from 'components/card/change/CardChange'

import useI18n from 'use/i18n/useI18n'

import DateModel from 'model/Date.model'
import UserExchangeModel, { IUserExchangeModel } from 'model/UserExchange.model'
import moment from 'moment'
import { ButtonAvatarChangeStyled } from 'components/card/change/styled'
import { ExchangeFullCompose } from './ExchangeFullCompose'
import { AvatarCompose } from 'compose/img/AvatarCompose'
import { ExchangeTotal } from 'compose/card/change/styled'

const CardChangeHomeComposeMemo: FC<IUserExchangeModel> = (data) => {
  const { t } = useI18n()
  const { change, user, exchanges } = data
  const { username = '' } = user || {}

  const total = UserExchangeModel.getTotal(exchanges)

  const stateActual = t(`state.actual.${change.stateId}`)
  const labelMine = t(`pages.dashboard.change.offer`)
  const labelHis = t(`pages.dashboard.change.search`)

  return user && (
    <CardChange
      name={username}
      avatar={<AvatarCompose hideBadge user={user} button={ButtonAvatarChangeStyled} />}
      hideIcon
      date={DateModel.dateDiff(moment(change.endDate).toISOString(), true)}
      stateActual={stateActual}>
      <>
        {exchanges.map((ob, i) => <ExchangeFullCompose key={i} exchange={ob} />)}
        <ExchangeTotal mine={total.mine} his={total.his} labelMine={labelMine} labelHis={labelHis}/>
      </>
    </CardChange>
  )
}

export const CardChangeHomeCompose = memo(CardChangeHomeComposeMemo)