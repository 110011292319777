import { IconDefinition } from 'components/theme/icons';
import { CustomLink } from 'components/button/CustomLink';
import React, { FC } from 'react';
import { NavTabWrapper, NavTab, NavTabIcon } from './styled';

interface Tab {
  icon?: IconDefinition;
  button: string;
  href?: string;
  onClick?: () => void;
}
interface IProps {
  invert?: boolean,
  color?: string;
  tabs?: Tab[];
};

export const NavTabs: FC<IProps> = ({
  color,
  invert = false,
  tabs,
}) => {

  return (
  <NavTabWrapper>
    {tabs.map(({ button, icon, href }, i) => (
      <CustomLink key={i} out={href}>
        <NavTab invert={invert} color={color}>
        { icon && <NavTabIcon icon={icon}/>}
        { button }
        </NavTab>
      </CustomLink>
      )
    )}
  </NavTabWrapper>
  )
}
